<template>
  <div class='full-width d-flex justify-between align-center flex-wrap-wrap'>
    <EmailScreen
        v-if='activeStep === 1'
        :email='form.email'
        :offer='salesRepresentativeOffer'
        flow-type='registration'
        @update='handleUpdate'
        @complete='activeStep = 2'
    />
    <ConfirmOtpScreen
        v-if='activeStep === 2'
        :email='form.email'
        @back='activeStep = 1'
        @complete='activeStep = 3'
    />
    <template v-if='!showDownloadApps'>
      <RegistrationScreen
          v-if='activeStep === 3'
          @complete='handleCompleteRegistration'
      />
      <BuyPackageScreen
          v-if='activeStep === 4'
          @back='activeStep = 3'
          @complete='handleRedirect'
      />
    </template>
    <template v-else>
      <template v-if='salesRepresentativeOffer?.id'>
        <DownloadAppScreen>
          <div class='auth-title'>
            {{ $t('successRegisterTitle') }}
          </div>
          <div class='fz-16 lh-14' v-html='$t("successRegisterWithSalesLink")'></div>
        </DownloadAppScreen>
      </template>
      <template v-else>
        <DownloadAppScreen>
          <div class='auth-title'>
            {{ $t('successRegisterTitle') }}
          </div>
          <div class='fz-16 lh-14' v-html='$t("successRegisterWithoutSalesLink", {email: "sales@ventrixai.com"})'></div>
        </DownloadAppScreen>
      </template>
    </template>
  </div>
</template>
<script setup>
import BuyPackageScreen from './components/BuyPackageScreen.vue';
import ConfirmOtpScreen from './components/ConfirmOtpScreen.vue';
import DownloadAppScreen from './components/DownloadAppScreen.vue';
import EmailScreen from './components/EmailScreen.vue';
import RegistrationScreen from './components/RegistrationScreen.vue';

import {useAuth} from '@/composable/auth';
import {useUser} from '@/composable/useUsers';
import {useSaleRepresentative} from '@/hooks/useSaleRepresentative';

import {clearTokens} from '@/helpers/tokens';

import {inject, reactive, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute, useRouter} from 'vue-router';

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const locale = i18n.locale.value;
const activeStep = ref(1);
const {t} = useI18n();
const toast = inject('WKToast');

const {setAuthStatus} = useAuth();
const {profile} = useUser();

clearTokens();
setAuthStatus(false);

const form = reactive({
  email: '',
  otp: ''
});

const {
  fetchSalesRepresentativeOffer,
  salesRepresentativeOffer,
  createClickOnLink,
  assignLicenseHolderToSalesRepr
} = useSaleRepresentative();
if (route.query.slug) fetchSalesRepresentativeOffer({slug: route.query.slug});

const showDownloadApps = ref(false);
const handleRedirect = async () => {
  showDownloadApps.value = true;
};

const handleUpdate = params => {
  Object.assign(form, params);
};

const handleCompleteRegistration = async params => {
  const {newPackage, currentPackage} = params;
  if (!salesRepresentativeOffer.value?.id) {
    toast(t('youCantRegisterWithoutSaleRepr'), {className: 'wk-alert'});
    return;
  }
  await createClickOnLink({
    linkId: salesRepresentativeOffer.value.id,
    salesManagerUserId: salesRepresentativeOffer.value.userId,
    customerUserId: profile.value.userId
  });
  try {
    await assignLicenseHolderToSalesRepr({
      salesRepresentativeId: salesRepresentativeOffer.value.userId,
      userId: profile.value.userId
    });
  } finally {
    if (currentPackage === newPackage) {
      await handleRedirect();
    } else {
      await router.replace({name: route.name, params: {locale: locale}, query: {package: newPackage}});
      activeStep.value++;
    }
  }
};
</script>
