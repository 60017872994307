<template>
  <div class='col-sm-12 col-6'>
    <div class='auth-title'>
      {{ contentForFlowType[flowType].title }}
    </div>
    <div class='auth-sub_title'>
      {{ contentForFlowType[flowType].subtitle }}
    </div>
    <form class='auth-form' @submit.prevent='handleFormSendEmail'>
      <BaseInput
        name='email'
        type='email'
        :label="$t('email')"
        :ref='el => formRef.push(el)'
        :autocomplete='false'
        filled
        size='large'
        :value='form.email'
        :server-errors='emailErrors'
        :rules='{
           email: true,
           required: true,
        }'
        @on-update='form.email = $event'
      />
      <BaseCheckbox
        v-if='flowType === flowTypes.registration'
        class='mt-1 mb-5'
        name='acceptAgreement'
        id='acceptAgreement'
        size='large'
        :ref='el => formRef.push(el)'
        :rules='{ requiredTrue: true, required: true }'
        :checked='!!form.acceptAgreement'
        @on-update='form.acceptAgreement = !form.acceptAgreement'>
        <p class='acceptAgreement-text'>
          {{ $t('accept') }}
          <RouterLink :to='{ name: "TermsOfUse", params: $route.params }'>
            {{ $t('termsAndConditions') }}
          </RouterLink>
        </p>
      </BaseCheckbox>
      <div class='d-flex justify-start mt-5 pt-4'>
        <BaseButton
          size='large'
          style='min-width: 200px'
          :loading='sendEmail.isLoading.value'
          @on-click='handleFormSendEmail'>
          {{ contentForFlowType[flowType].btnTitle }}
        </BaseButton>
      </div>
    </form>
  </div>
  <div class='col-5 col-sm-12 col-md-6 mt-sm-5 d-none-sm'>
<!--    <div class='auth-block'>-->
<!--      <div class='auth-block__logo'>-->
<!--        <img src='@/assets/images/ventrix-long.png' alt=''>-->
<!--      </div>-->
<!--      <div class='auth-block__title'>-->
<!--        {{$t('loginBlockTitle')}}-->
<!--      </div>-->
<!--      <div class='auth-block__text'>-->
<!--        {{$t('loginBlockText1')}}-->
<!--        <br><br>-->
<!--        {{$t('loginBlockText2')}}-->
<!--        <br><br>-->
<!--        {{$t('loginBlockText3')}}-->
<!--        <br><br>-->
<!--        {{$t('loginBlockText4')}}-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script setup>
import BaseButton from '@/components/atoms/BaseButton.vue';
import BaseCheckbox from '@/components/atoms/BaseCheckbox.vue';
import BaseInput from '@/components/atoms/BaseInput.vue';

import {useAuth} from '@/composable/auth';

import {validationForm} from '@/helpers/validation';

import {computed, defineEmits, defineProps, reactive, ref, toRefs, watch} from 'vue';
import {useI18n} from 'vue-i18n';

const flowTypes = {
  login: 'login',
  registration: 'registration'
};
const { t } = useI18n();

const contentForFlowType = {
  login: {
    title: t('loginTitle'),
    subtitle: t('loginSubtitle'),
    btnTitle: t('signIn'),
  },
  registration: {
    title: t('registrationTitle'),
    subtitle: t('registrationSubtitle'),
    btnTitle: t('signUp'),
  }
};

const emit = defineEmits(['complete', 'update']);
const props = defineProps({
  email: {
    type: String,
    default: ''
  },
  flowType: {
    type: String,
    required: true
  },
  offer: {
    type: [Object, null],
    default: null
  }
});

const form = reactive({
  email: ''
});

const { email } = toRefs(props);
watch(() => email, () => {
  form.email = email.value;
}, { immediate: true });

const { sendEmail } = useAuth();
const emailErrors = computed(() => {
  if (!sendEmail.isError.value) return null;
  if (sendEmail.error.value?.response?.data.status === 500) return null;

  if (sendEmail.error.value?.response?.data.data?.type) {
    return [t(`serverError_login_${sendEmail.error.value?.response?.data.data?.type}`)];
  } else {
    return [t(`serverError_login_${Object.keys(sendEmail.error.value?.response?.data.data)[0]}`)];
  }
});

const i18n = useI18n();
const formRef = ref([]);
const handleFormSendEmail = async () => {
  if (!validationForm(formRef.value)) return;
  try {
    const locale = i18n.locale.value;
    await sendEmail.mutateAsync({
      email: form.email,
      template: 1,
      lang: locale
    });
    emit('complete');
    emit('update', form);
  } catch (err) {
    throw new Error('Cant send email');
  }
};
</script>
<style lang='scss'>
.acceptAgreement-text{
  a{
    color: $red;
    &:hover{
      text-decoration: none;
    }
  }
}
</style>
