import api from '@/api/saleRepresentative';

import {useMutation, useQuery} from '@/helpers/xQuery';

const {
  fetchData: fetchClickOnLinks,
  data: clickOnLinks,
  loading: clickOnLinksLoading
} = useQuery('click-on-links', api.fetchClickOnLinks);

export function useSaleRepresentative() {
  const {
    fetchData: fetchSalesRepresentativeOffer,
    data: salesRepresentativeOffer,
    loading: salesRepresentativeLoading
  } = useQuery('sales-representative-offer', api.fetchOffer);

  const {sendRequest, loading: actionLoading} = useMutation();
  const createClickOnLink = async payload => await sendRequest(api.createClickOnLinks, payload);

  const assignLicenseHolderToSalesRepr = async payload =>
    await sendRequest(api.assignLicenseHolderToSalesRepr, payload);

  return {
    assignLicenseHolderToSalesRepr,
    fetchClickOnLinks,
    clickOnLinks,
    clickOnLinksLoading: actionLoading || clickOnLinksLoading,
    createClickOnLink,
    fetchSalesRepresentativeOffer,
    salesRepresentativeOffer,
    salesRepresentativeLoading
  };
}
