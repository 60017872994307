import $http, {$httpAccounting} from '@/utils/https';

const API_URL = `${process.env.VUE_APP_APIGW_URL}/chatbots/sale-representative`;

export default {
  async fetchOffer({slug}) {
    const response = await $httpAccounting(`/open/user/registration/slug/${slug}/`);
    return response.data;
  },
  async createClickOnLinks(payload) {
    const response = await $http.post(`${API_URL}/clicks-on-links`, payload);
    return response.data.data;
  },
  async fetchClickOnLinks() {
    const response = await $http(`${API_URL}/clicks-on-links`);
    return response.data.data;
  },
  async assignLicenseHolderToSalesRepr(payload) {
    const response = await $httpAccounting.post(`/commissions/license-holder/`, payload);
    return response.data;
  }
};
