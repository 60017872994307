<template>
  <div class='auth-confirmOtp full-width'>
    <div class='col-6 col-sm-12'>
      <div class='auth-back'>
        <a href='#' @click.prevent='$emit("back")'>
          <Icon name='chevron-left' />
          <span>
          {{ $t('back') }}
        </span>
        </a>
      </div>
      <div class='auth-title'>
        {{$t('confirmOtpTitle')}}
      </div>
      <div class='auth-sub_title'>
        {{$t('confirmOtpDesc')}}
      </div>
      <form class='auth-form' @submit.prevent='handleFormConfirmOtp'>
        <BaseInput
          name='otp'
          :label="$t('enterOtp')"
          :ref='el => formRef.push(el)'
          :autocomplete='false'
          filled
          size='large'
          :server-errors='otpErrors'
          :rules='{
          number: true,
          maxLength: 6,
          required: true,
        }'
          @on-update='form.otp = $event'
        />
        <div class='d-flex justify-start mt-5'>
          <BaseButton
            size='large'
            style='min-width: 200px'
            :loading='confirmEmailOtp.isLoading.value'
            @on-click='handleFormConfirmOtp'>
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </form>
    </div>
    <div class='col-12 d-flex align-end justify-end d-none-sm' style='margin-top: 10%;'>
      <img src='@/assets/images/ventrix-logo-black.png' alt=''>
    </div>
  </div>
</template>
<script setup>
import BaseButton from '@/components/atoms/BaseButton.vue';
import BaseInput from '@/components/atoms/BaseInput.vue';
import Icon from '@/components/atoms/Icon.vue';

import {useAuth} from '@/composable/auth';

import {saveTokensToStorage} from '@/helpers/tokens';
import {validationForm} from '@/helpers/validation';

import {computed, defineEmits, defineProps, reactive, ref, toRefs} from 'vue';
import {useI18n} from 'vue-i18n';

const props = defineProps({
  email: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['complete', 'back']);

const { t } = useI18n();
const { email } = toRefs(props);
const formRef = ref([]);

const { confirmEmailOtp, setAuthStatus } = useAuth();

const form = reactive({
  otp: ''
});

const otpErrors = computed(() => {
  if (!confirmEmailOtp.isError.value) return null;
  return confirmEmailOtp.isError.value && [t(`serverError_login_${confirmEmailOtp.error.value?.response?.data.data?.type}`)];
});

const handleFormConfirmOtp = async () => {
  if (!validationForm(formRef.value)) return;
  try {
    const result = await confirmEmailOtp.mutateAsync({
      ...form,
      email: email.value
    });
    if (Object.keys(result).length !== 0) {
      saveTokensToStorage(result.token);
      setAuthStatus(true);
      emit('complete');
    }
  } catch (err) {
    throw new Error('Otp confirm error');
  }
};

</script>
